// React-specific imports
import React, { useCallback, useMemo, useState } from 'react';

// Material-UI (MUI) component and utility imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';

// Local file imports and styles
import AnimatedGrid from './Animated/AnimatedGrid';
import './App.css';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: red,
    },
});

function CoinFlip() {
    const [results, setResults] = useState([]);
    const [numCoins, setNumCoins] = useState(5);
    const [flipWithAdvantage, setFlipWithAdvantage] = useState(false);
    const [flipDetails, setFlipDetails] = useState([]);
    const [showGrid, setShowGrid] = useState(false);

    const logAndResultFlip = useCallback(
        (result, isAdvantage = false, secondResult = null) => {
            const detailEntry = isAdvantage ? `${result} / ${secondResult}` : result;
            setFlipDetails(prevDetails => [...prevDetails, detailEntry]);
    
            return (result === 'Win' || secondResult === 'Win') ? 'Win' : 'Lose';
        },
        [setFlipDetails]
    );
    

    const flipCoin = useCallback(() => {
        const firstFlip = Math.random() > 0.5 ? 'Win' : 'Lose';
        const result = flipWithAdvantage ? logAndResultFlip(firstFlip, true, Math.random() > 0.5 ? 'Win' : 'Lose') : logAndResultFlip(firstFlip);
        return [result, flipWithAdvantage ? 'Advantage' : 'Single'];
    }, [flipWithAdvantage, logAndResultFlip]);
    

    const startGridAnimation = useCallback(() => {
        setShowGrid(true);
    }, []);

    const flipCoins = useCallback(
        (times = 1) => {
            startGridAnimation();
            setResults([]);
            setFlipDetails([]);
            const outcome = [];
            for (let i = 0; i < times; i++) {
                outcome.push(flipCoin());
            }
            setResults(outcome);
        },
        [flipCoin, startGridAnimation]
    );
    

    const flipUntilLose = useCallback(() => {
        startGridAnimation();
        setResults([]);
        setFlipDetails([]);
        const newResults = [];
        let flipResult = flipCoin();
        while (flipResult[0] !== 'Lose') {
            newResults.push(flipResult);
            flipResult = flipCoin();
        }
        newResults.push(flipResult); 
        setResults(newResults);
    }, [flipCoin, startGridAnimation]);
    

    const calculateWins = useMemo(
        () => results.filter((result) => result[0] === 'Win').length,
        [results]
    );

    const calculateTotalFlips = useMemo(
        () =>
            flipDetails.reduce(
                (count, detail) => count + (detail.includes('/') ? 2 : 1),
                0
            ),
        [flipDetails]
    );

    const factorial = useCallback((n) => {
        return n <= 1 ? 1 : n * factorial(n - 1);
    }, []);
    

    const calculateProbability = useCallback(
        (n, k) => {
            const combinations = factorial(n) / (factorial(k) * factorial(n - k));
            const totalOutcomes = Math.pow(2, n);
            const probability = combinations / totalOutcomes;
            // Convert probability to odds
            const odds = 1 / probability - 1;
            // Format as ratio
            const oddsRatio = odds > 1 ? `1 : ${Math.round(odds)}` : `${Math.round(1 / odds)} : 1`;
            return oddsRatio;
        },
        [factorial]
    );
    
    
    const handleGridAnimationComplete = useCallback(
        () => setShowGrid(false),
        []
    );

    return (
        <ThemeProvider theme={darkTheme}>
            <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                color="text.primary"
            >
                <Box
                    display="flex"
                    justifyContent="space-around"
                    padding={2}
                    backgroundColor={'background.default'}
                >
                    <Button
                        onClick={() => flipCoins(1)}
                        variant="outlined"
                        size="large"
                        sx={{ width: '20vw' }}
                    >
                        1 Coin
                    </Button>
                    <Button
                        onClick={() => flipCoins(3)}
                        variant="outlined"
                        size="large"
                        sx={{ width: '20vw' }}
                    >
                        3 Coins
                    </Button>
                    <Button
                        onClick={() => flipCoins(numCoins)}
                        variant="outlined"
                        size="large"
                        sx={{ width: '20vw' }}
                    >
                        {numCoins} Coins
                    </Button>
                    <Button
                        onClick={flipUntilLose}
                        variant="outlined"
                        size="large"
                        sx={{ width: '20vw' }}
                    >
                        Until Lose
                    </Button>
                </Box>

                <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    padding={2}
                    backgroundColor={'background.default'}
                >
                    <Slider
                        defaultValue={3}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        min={1}
                        max={100}
                        value={numCoins}
                        onChange={(e, newValue) => setNumCoins(newValue)}
                    />
                    <TextField
                        fullWidth
                        type="number"
                        value={numCoins}
                        label="Coins"
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) =>
                            setNumCoins(parseInt(e.target.value, 10) || 1)
                        }
                        sx={{ maxWidth: 60 }}
                    />
                </Box>

                <Box
                    alignItems="center"
                    backgroundColor={'background.default'}
                    boxShadow="0 10px 8px 0 rgba(0, 0, 0, .5)"
                    display="flex"
                    justifyContent="space-around"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={flipWithAdvantage}
                                onChange={(e) =>
                                    setFlipWithAdvantage(e.target.checked)
                                }
                            />
                        }
                        label="Advantage"
                    />
                </Box>

                <Box
                    overflow="auto"
                    display="flex"
                    flexWrap="wrap"
                    gap={0.75}
                    justifyContent="center"
                    padding="2.5vh 5vw 20vh 5vw"
                >
                    {flipDetails.map((detail, index) => (
                        <Box
                            key={index}
                            width="20vw"
                            height="20vw"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="50%"
                            color="white"
                            fontWeight="bold"
                            fontSize="2.5vh"
                            backgroundColor={
                                detail.match(/Win/) ? green[500] : red[500]
                            }
                            className={
                                detail.match(/\//)
                                    ? detail.match(/Win/)
                                        ? 'glow-green'
                                        : 'glow-red'
                                    : 'glow-neutral'
                            }
                        >
                            {detail.match(/Win/) ? 'WON' : 'LOST'}
                        </Box>
                    ))}
                </Box>
                <Box
                    position="fixed"
                    bottom={0}
                    width="90vw"
                    backgroundColor="rgba(0, 0, 0, 0.90)"
                    borderRadius="12px"
                    margin="2.5vw"
                    padding="2.5vw"
                    boxShadow="0 0 5px 0 rgba(0, 0, 0, .5)"
                    zIndex={500}
                >
                    <Typography component="p" variant="body1">
                        Total Coins Flipped: {calculateTotalFlips}
                    </Typography>
                    <Typography component="p" variant="body1">
                        Odds: {calculateProbability(calculateTotalFlips, calculateWins)}
                    </Typography>
                    <Typography component="p" variant="h2">
                        Wins:{' '}
                        <span style={{ fontWeight: 'bold', color: 'red' }}>
                            {calculateWins}
                        </span>
                    </Typography>
                </Box>
            </Box>
            <AnimatedGrid
                show={showGrid}
                onAnimationComplete={handleGridAnimationComplete}
            />
        </ThemeProvider>
    );
}

export default CoinFlip;
