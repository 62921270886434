import React, { useEffect, useState } from 'react';
import anime from 'animejs/lib/anime.es.js';
import './AnimatedGrid.css';

const AnimatedGrid = ({ show, onAnimationComplete }) => {
    const [circleSize, setCircleSize] = useState(0);
    const [gridSize, setGridSize] = useState({ cols: 0, rows: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const margin = 0;

    const handleOnClick = () => {
        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Generate random x and y within the viewport
        const x = Math.floor(Math.random() * viewportWidth);
        const y = Math.floor(Math.random() * viewportHeight);

        // Update startPosition with random x and y
        setStartPosition({ x, y });
    };

    useEffect(() => {
        // Attach click event listener to the window
        window.addEventListener('click', handleOnClick);

        return () => {
            // Cleanup the event listener on component unmount
            window.removeEventListener('click', handleOnClick);
        };
    }, []);

    useEffect(() => {
        const size = Math.min(window.innerWidth, window.innerHeight) / 25;
        setCircleSize(size);

        const cols = Math.floor(window.innerWidth / (size + margin));
        const rows = Math.floor(window.innerHeight / (size + margin));
        setGridSize({ cols, rows });

        if (show) {
            // Calculate closest grid cell to the click position
            const colClicked = Math.floor(startPosition.x / (size + margin));
            const rowClicked = Math.floor(startPosition.y / (size + margin));
            const randomStartIndex = colClicked + rowClicked * cols;

            anime({
                targets: '.circle',
                scale: [
                    { value: 0, easing: 'easeOutSine', duration: 250 },
                    { value: 1, easing: 'easeInOutQuad', duration: 500 },
                    { value: 0, easing: 'easeOutSine', duration: 150 },
                ],
                delay: anime.stagger(25, {
                    grid: [cols, rows],
                    from: randomStartIndex,
                }),
                complete: onAnimationComplete,
            });
        }
    }, [
        show,
        onAnimationComplete,
        gridSize.cols,
        gridSize.rows,
        startPosition,
    ]);

    return show ? (
        <div
            className="overlay"
            display="grid"
            style={{
                gridTemplateColumns: `repeat(${gridSize.cols}, 1fr)`,
                gap: `${margin}px`,
            }}
        >
            {Array.from({ length: gridSize.cols * gridSize.rows }, (_, i) => (
                <div
                    key={i}
                    className="circle"
                    style={{
                        width: `${circleSize}px`,
                        height: `${circleSize}px`,
                        margin: `${margin}px`,
                    }}
                ></div>
            ))}
        </div>
    ) : null;
};

export default AnimatedGrid;
